import React, { useContext, useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useMediaQuery, Avatar, Menu, MenuItem } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { getAccessToken } from "../../../configs/config";
import background from "../../../assets/img/background.png";
import Logo from "../../../assets/img/logo/logo-icon.png";
import { AppContext } from "../../../context/AppContext";

const isSmartNavVisible = process.env.REACT_APP_IS_SMART_NAV_VISIBLE === "True";

const drawerWidth = 240;
const navItems = ["Home", "Maps", "Candidate Smart Analysis"];

const DrawerAppBar = (props) => {
    const { window } = props;
    const { isUserMasterAdmin } = useContext(AppContext);
    const [mobileOpen, setMobileOpen] = useState(false);
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    const navigate = useNavigate();
    const location = useLocation();
    const [anchorEl, setAnchorEl] = useState(null);

    const [userInfo, setUserInfo] = useState({
        name: "User",
        role: "Role",
        picture: "",
    });

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const fetchData = async () => {
        try {
            // eslint-disable-next-line consistent-return
            await Auth.currentUserInfo().then((data) => {
                if (!data && getAccessToken() === null) {
                    return (window.location = "/login");
                }

                setUserInfo({
                    name: data?.attributes?.name,
                    role: "Administrator",
                    picture: data?.attributes?.picture || "",
                });
            });
        } catch (err) {
            // DO NOTHING
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = (url = "") => {
        setAnchorEl(null);

        try {
            if (url) {
                navigate(url);
            }
        } catch (err) {
            console.error("Errored Menu");
        }
    };

    const handleNavItemClick = (item) => {
        switch (item) {
            case "Home":
                navigate("/");
                break;

            case "Maps":
                navigate("/maps");
                break;

            case "Candidate Smart Analysis":
                navigate("/candidate-smart-analysis");
                break;

            default:
                break;
        }
        setMobileOpen(false);

        if (isMobile) {
            handleDrawerToggle(false);
        }
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
            <Typography variant="h4" sx={{ my: 2 }}>
                {"ELEKSYON 2022\r"}
            </Typography>
            <Divider />
            <List>
                {navItems.map((item) => {
                    if (
                        !isSmartNavVisible &&
                        item === "Candidate Smart Analysis"
                    ) {
                        return null;
                    }

                    return (
                        <ListItem key={item}>
                            <ListItemButton
                                sx={{ textAlign: "center" }}
                                onClick={() => handleNavItemClick(item)}
                            >
                                <ListItemText
                                    primary={item}
                                    primaryTypographyProps={{
                                        sx: { fontSize: "24px" },
                                    }}
                                />
                            </ListItemButton>
                        </ListItem>
                    );
                })}
            </List>
        </Box>
    );

    const container =
        window !== undefined ? () => window().document.body : undefined;

    const isHomePage =
        location.pathname === "/" || location.pathname === "/home";
    const isWithLocation = location.pathname.includes("location");

    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <AppBar
                component="nav"
                sx={{
                    boxShadow: "none",
                    backgroundColor: isHomePage ? "transparent" : "none",
                    backgroundImage: isHomePage ? "none" : `url(${background})`,
                    marginRight: isWithLocation ? "0.9rem" : "0px",
                }}
            >
                <Toolbar sx={{ padding: "24px" }}>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            flexGrow: 1,
                        }}
                    >
                        <img
                            src={Logo}
                            href="/"
                            alt="Eleksyon2022"
                            style={{
                                width: "50px",
                                marginRight: "10px",
                                display: isMobile ? "none" : "initial",
                            }}
                        />
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{ mr: 2, display: { sm: "none" } }}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Box>
                    <Box
                        sx={{
                            display: { xs: "none", sm: "flex" },
                            alignItems: "center",
                        }}
                    >
                        {navItems.map((item) => {
                            if (
                                !isSmartNavVisible &&
                                item === "Candidate Smart Analysis"
                            ) {
                                return null;
                            }

                            return (
                                <Button
                                    key={item}
                                    sx={{
                                        color: "#fff",
                                        padding: "6px 40px",
                                        fontSize: "1rem",
                                    }}
                                    onClick={() => handleNavItemClick(item)}
                                >
                                    {item}
                                </Button>
                            );
                        })}
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                ml: 2,
                            }}
                        >
                            {userInfo.picture ? (
                                <Avatar
                                    sx={{ mr: 1 }}
                                    src={userInfo.picture}
                                    alt={userInfo.name}
                                />
                            ) : (
                                <Avatar sx={{ mr: 1 }}>
                                    {userInfo.name
                                        ? userInfo.name
                                              .split(" ")
                                              .map((word) => word[0])
                                              .join("")
                                              .toUpperCase()
                                              .slice(0, 2)
                                        : "U"}
                                </Avatar>
                            )}
                            <Button
                                onClick={handleMenuClick}
                                sx={{
                                    color: "#fff",
                                    textTransform: "none",
                                    flexDirection: "column",
                                }}
                            >
                                <Typography
                                    variant="body1"
                                    sx={{ mr: 1, display: "block" }}
                                >
                                    {userInfo.name}
                                </Typography>

                                <Typography
                                    variant="body1"
                                    sx={{
                                        mr: 1,
                                        fontSize: "12px",
                                        display: "block",
                                    }}
                                >
                                    {userInfo.role}
                                </Typography>
                            </Button>
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                            >
                                <MenuItem
                                    onClick={() =>
                                        handleMenuClose("/change-password")
                                    }
                                >
                                    {"Change Password"}
                                </MenuItem>{" "}
                                {/* Added MenuItem components */}
                                <MenuItem
                                    onClick={() =>
                                        handleMenuClose("/profile-edit")
                                    }
                                >
                                    {"Profile"}
                                </MenuItem>
                                {isUserMasterAdmin && (
                                    <MenuItem
                                        onClick={() =>
                                            handleMenuClose("/user-edit")
                                        }
                                    >
                                        {"Edit Accounts"}
                                    </MenuItem>
                                )}
                                <MenuItem
                                    onClick={() => {
                                        localStorage.clear();
                                        navigate("/login");
                                    }}
                                >
                                    {"Logout"}
                                </MenuItem>
                            </Menu>
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>
            <Box component="nav">
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: "block", sm: "none" },
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: drawerWidth,
                        },
                    }}
                >
                    {drawer}
                </Drawer>
            </Box>
        </Box>
    );
};

DrawerAppBar.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    // eslint-disable-next-line react/require-default-props
    window: PropTypes.func,
};

export default DrawerAppBar;
